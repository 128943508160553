export const DashboardsNav = (accountSubscriptionLevel, locationSubscriptionLevel) => [
  {
    icon: "gauge-high",
    label: "Dashboard",
    to: "/dashboards/ops/",
  },
  ...(accountSubscriptionLevel >= 2 ? [{
    icon: "chart-line",
    label: "Business Reports",
    content: [
      {
        label: "Pricing & Profits",
        to: "/reports/pricing_and_profits/",
      },
      {
        label: "Missing Modifier Amounts",
        to: "/reports/missing_modifiers/",
      },
      {
        label: "Weekly COGS",
        to: "/reports/weekly_cogs/",
      },
    ],
  }] : []),
  ...(locationSubscriptionLevel >= 2 ? [{
    icon: "chart-line",
    label: "Location Reports",
    content: [
      {
        label: "Checklist Completion",
        to: "/reports/location/checklist_report/"
      },
      {
        label: "Weekly COGS",
        to: "/reports/location/weekly_cogs/",
      },
      {
        label: "Sales & Staffing Matrix",
        to: "/reports/location/staffing_matrix/",
      },
      {
        label: "Product Quadrants",
        to: "/reports/location/quadrants/"
      }
    ],
  }] : []),
];

// Similar updates for other navigation items if needed
export const InventoryNav = (locationSubscriptionLevel) => [
  {
    icon: "list-check",
    label: "Count",
    content: [
      {
        label: "Full Count",
        to: "/inventory/full_count/",
      },
      {
        label: "Limited Count",
        to: "/inventory/limited_count/",
      },
      {
        icon: "magnifying-glass-location",
        label: "Inventory Zones",
        to: "/inventory/inventory_zones/",
      },
    ]
  },
  {
    icon: "cart-shopping",
    label: "Ordering",
    content: [
      {
        label: "Create New Order",
        to: "/inventory/new_order/",
      },
      {
        label: "Receive Order",
        to: "/inventory/receive_order/",
      },
    ],
  },
  {
    icon: "bars",
    label: "Ledger",
    to: "/inventory/",
  },
];
export const RecipesNav = (locationSubscriptionLevel) => [
  ...(locationSubscriptionLevel >= 2
    ? [
        {
          icon: "tachograph-digital",
          label: "Recipes",
          content: [
            {
              icon: "tachograph-digital",
              label: "Recipes",
              to: "/ingredients/recipes/",
            },
            {
              icon: "location-crosshairs",
              label: "Prep Categories",
              to: "/ingredients/prep_categories/",
            },
/*
            {
              icon: "location-crosshairs",
              label: "Bake Groups",
              to: "/admin/bake_groups/",
            },
*/
          ],
        },
      ]
    : []),
  {
    icon: "mortar-pestle",
    label: "Ingredients",
    content: [
      {
        icon: "mortar-pestle",
        label: "Ingredients",
        to: "/ingredients/",
      },
      ...(locationSubscriptionLevel >= 2
        ? [
            {
              icon: "pen-to-square",
              label: "Modifiers",
              to: "/ingredients/modifiers/",
            },
          ]
        : []),
      {
        icon: "location-crosshairs",
        label: "Brands",
        to: "/admin/brands/",
      },
      {
        icon: "location-crosshairs",
        label: "Suppliers",
        to: "/admin/suppliers/",
      },
    ],
  },
];

export const MenuNav = (locationSubscriptionLevel) => 
  (locationSubscriptionLevel >= 3 ? [
    {
      icon: "pen-to-square",
      label: "Menus",
      to: "/menu/dashboard/",
    },
  ] : []);
export const ChecklistNav = (locationSubscriptionLevel) => [
  ...(locationSubscriptionLevel >= 3 ? [{
    icon: "square-check",
    label: "Checklists",
    to: "/admin/checklists/",
  }] : []),
];
export const TabletNav = (locationSubscriptionLevel, locationlocationSubscriptionLevel) => [
  ...(locationSubscriptionLevel > 2 ? [
    {
      icon: "tablet-screen-button",
      label: "Tablets",
      content: [{
          label: "Tablet Info",
          to: "/tablets/info/",
        },
        ...(locationlocationSubscriptionLevel >= 3 ? [
          {
            icon: "table-columns",
            label: "KDS Admin",
            to: "/tablets/kds/",
          },
        ] : []),
        {
          icon: "table-columns",
          label: "Device Settings",
          to: "/tablets/devices",
        },
      ],
    },
    /*
    {
      icon: "tv",
      label: "Screens",
      content: [{
          label: "Screen Info",
          to: "/screens/info/",
        },
        ...(locationSubscriptionLevel >= 3 ? [
          {
            icon: "table-columns",
            label: "Pickup Screen Admin",
            to: "/screens/pickup/",
          },
        ] : [])
      ],
    },
    */
  ] : []),
];
